<template>
  <div class="overflow-auto all-products w-full p-10 product">
    <template v-if="product">
      <ProductForm @deleteImgGallery="deleteImgGallery" @deleteProductImage="deleteProductImage"
                   @addAttrToProductsAttributes="addAttrToProductsAttributes"
                   @addVariationToProduct="addVariationToProduct" @formSubmit="sendData"
                   :product="product"></ProductForm>
    </template>
  </div>
</template>

<script setup>
import ProductForm from "@/components/ProductForm.vue";
import axios       from 'axios';
import {ref}       from 'vue'
import {Config}    from "@/config";
import {useRouter} from 'vue-router'
import Until       from "@/until";
import {format}    from "date-fns";
import {createToaster} from "@meforma/vue-toaster";

const Toaster = createToaster({ position:'bottom-right', duration:3000,useDefaultCss: true});

const {createUUID} = Until;
const product = ref();
const Router = useRouter();

product.value = {
  post_title  : '',
  post_content: '',
  post_excerpt: '',
  post_status : 'publish',
  type:'simple',
  attribute_terms: {},
  variations_by_id: {},
  product_cat: [],
  product_tag: [],
  product_img: {},
  product_gallery: [],
  post_meta   : {
    algemene_informatie           : '',
    opstapplaatsen                : '',
    extra_informatie              : '',
    opsomming                     : [],
    _tc_is_ticket                 : '',
    _event_name                   : '',
    _stock_status                 : '',
    _sku                          : '',
    _available_checkins_per_ticket: '',
    _allow_ticket_checkout        : '',
    _ticket_availability          : 'open_ended',
    _ticket_availability_from_date: '',
    _ticket_availability_to_date  : '',
    _ticket_checkin_availability  : 'open_ended',
    _ticket_checkin_availability_from_date  : '',
    _ticket_checkin_availability_to_date  : '',
    _time_after_order_days  : '',
    _time_after_order_hours  : '',
    _time_after_order_minutes  : '',
    _time_after_first_checkin_days  : '',
    _time_after_first_checkin_hours  : '',
    _time_after_first_checkin_minutes  : '',
    _rank_math_gtin_code  : '',
    _manage_stock  : 'no',
    _stock  : '',
    _backorders  : '',
    _low_stock_amount  : '',
    _sold_individually  : 'no',
    _sale_price  : '',
    _regular_price  : '',
    _product_attributes  : {},
  },
}

const API_URL_POST = Config.APP_WP_URL + '/wp-json/api/headless/products/create';

function addAttrToProductsAttributes(data)
{
  product.value.post_meta._product_attributes[data.name] = data;
}

function addVariationToProduct(data)
{
  const key = createUUID();
  product.value.variations_by_id[key] = data;
}

async function sendData(data, imgFile, galleryFiles)
{
  data = JSON.parse(JSON.stringify(data))
  const formData = new FormData();
  data.post_meta.opsomming = data.post_meta.opsomming.map((el) => el.value)
  data.post_meta._ticket_availability_from_date = data.post_meta._ticket_availability_from_date ? format(new Date(data.post_meta._ticket_availability_from_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_meta._ticket_availability_to_date = data.post_meta._ticket_availability_to_date ? format(new Date(data.post_meta._ticket_availability_to_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_date = data.post_date ? format(new Date(data.post_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_img = imgFile;

  formData.append('data', JSON.stringify(data));
  formData.append('img', imgFile);
  galleryFiles.forEach((file) => {
    formData.append(file.name, file);
  })
  const response = await axios.post(API_URL_POST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  const {value: id} = JSON.parse(response.data);

  if (response.status === 200) {
    Toaster.success('Opgeslagen', {});
    setTimeout(() => {
      Router.push({name: 'Product', params: {id}})
    },1000)
  } else {
    Toaster.error('Opslaan mislukt', {});
  }


}

function deleteProductImage()
{
  product.value.post_meta._thumbnail_id = '';
  product.value.product_img = null;
}

function deleteImgGallery(id)
{
  product.value.product_gallery = product.value.product_gallery.filter((el) => el.id !== id)
  let arrayOfImagesId = product.value.post_meta._product_image_gallery.split(',')
  arrayOfImagesId = arrayOfImagesId.filter((el) => el !== id);
  product.value.post_meta._product_image_gallery = arrayOfImagesId.join(',');
}

</script>

<style lang="scss">
.product {
  background-color: white;
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1380px) {
    padding: 30px;
  }
  @media (max-width: 768px) {
    padding: 15px;
  }
  & h1 {
    color: #1f1f1f;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
    margin-left: 24px;
    margin-bottom: 28px;
  }
}
</style>
