<template>
  <div class="overflow-auto w-full product">
    <h1>Wijzig product</h1>
    <template v-if="product">
      <ProductForm @deleteImgGallery="deleteImgGallery" @deleteProductImage="deleteProductImage"
                   @addAttrToProductsAttributes="addAttrToProductsAttributes"
                   @addVariationToProduct="addVariationToProduct" @formSubmit="sendData"
                   :product="product" :loading="loading"></ProductForm>
    </template>
  </div>
</template>

<script setup>
import Heading                    from "@/components/Heading";
import ProductForm                from "@/components/ProductForm.vue";
import axios                      from 'axios';
import {useRoute}                 from 'vue-router'
import {useStore}                 from 'vuex';
import {onMounted, computed, ref} from 'vue'
import until                      from '@/until/index.js';
import {Config}                   from "@/config";
import Until                      from '@/until/index'
import {createToaster}            from "@meforma/vue-toaster";
import {format}                   from 'date-fns'


const Toaster = createToaster({duration: 3000, useDefaultCss: true});
const route = useRoute();
const store = useStore();
const {replaceLineBreaks} = until
const productId = route.params.id;
// const product = ref();
const product = computed({
  get()
  {
    return store.state.products.product
  },
  set(value)
  {
    store.commit('products/SET_ACTIVE_PRODUCT', value)
  }
});
const loading = ref(false);
const {createUUID} = Until;


const API_URL_POST = Config.APP_WP_URL + '/wp-json/api/headless/products/save';

onMounted(async () => {
  product.value = await store.dispatch('products/getProduct', productId)
  product.value.post_meta.algemene_informatie = replaceLineBreaks(product.value.post_meta.algemene_informatie)
  product.value.post_meta.opstapplaatsen = replaceLineBreaks(product.value.post_meta.opstapplaatsen)
  product.value.post_meta.extra_informatie = replaceLineBreaks(product.value.post_meta.extra_informatie)
  product.value.post_meta.opsomming = product.value.post_meta.opsomming.map((item) => {
    return {id: createUUID(), value: item}
  })
  if (product.value.attribute_terms instanceof Array) {
    product.value.attribute_terms = {}
  }
});

function addAttrToProductsAttributes(data)
{
  if (product.value.post_meta._product_attributes instanceof Array) {
    product.value.post_meta._product_attributes = {};
  }
  product.value.post_meta._product_attributes[data.name] = data;
}

function addVariationToProduct(data)
{
  if (!product.value.variations_by_id) {
    product.value.variations_by_id = {}
  }
  const key = createUUID();
  product.value.variations_by_id[key] = data;
}

async function sendData(data, imgFile, galleryFiles)
{
  loading.value = true;
  data = JSON.parse(JSON.stringify(data))
  const formData = new FormData();
  data.post_meta.opsomming = data.post_meta.opsomming.map((el) => el.value)
  data.post_meta._ticket_availability_from_date = data.post_meta._ticket_availability_from_date ? format(new Date(data.post_meta._ticket_availability_from_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_meta._ticket_availability_to_date = data.post_meta._ticket_availability_to_date ? format(new Date(data.post_meta._ticket_availability_to_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_meta._ticket_checkin_availability_from_date = data.post_meta._ticket_checkin_availability_from_date ? format(new Date(data.post_meta._ticket_checkin_availability_from_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_meta._ticket_checkin_availability_to_date = data.post_meta._ticket_checkin_availability_to_date ? format(new Date(data.post_meta._ticket_checkin_availability_to_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_date = data.post_date ? format(new Date(data.post_date), 'yyyy-MM-dd HH:mm') : ''
  data.post_img = imgFile;

  formData.append('data', JSON.stringify(data));
  formData.append('img', imgFile);

  galleryFiles.forEach((file) => {
    formData.append(file.name, file);
  })

  const response = await axios.post(API_URL_POST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  if (response.status === 200) {
    loading.value = false;
    Toaster.success('Opgeslagen', {});
  } else {
    loading.value = false;
    Toaster.error('Opslaan mislukt', {});
  }
}

function deleteProductImage()
{
  product.value.post_meta._thumbnail_id = '';
  product.value.product_img = null;
}

function deleteImgGallery(id)
{
  product.value.product_gallery = product.value.product_gallery.filter((el) => {
    if (el.id) {
      return el.id !== id;
    }else {
      return el.name !== id;
    }
  });
  // let arrayOfImagesId = product.value.post_meta._product_image_gallery.split(',')
  // arrayOfImagesId = arrayOfImagesId.filter((el) => el !== id);
  // product.value.post_meta._product_image_gallery = arrayOfImagesId.join(',');
}


</script>

<style lang="scss">
.product {
  background-color: white;
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1380px) {
    padding: 30px;
  }
  @media (max-width: 768px) {
    padding: 15px;
  }

  & h1 {
    color: #1f1f1f;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
    margin-left: 24px;
    margin-bottom: 28px;
  }
}

.c-toast {
  width: 300px;
  height: 100px;
  font-size: 20px;
  align-self: unset;
  justify-content: center;
}

.c-toast-container {
  justify-content: center;
  align-items: center;
}

.c-toast--error {
  background-color: red;
}
</style>
